html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
#root {
    min-height: 100%;
    margin: 0;
    padding: 0;
}

a {
    color: darkgrey !important;
}

a:link,
a:visited {
    text-decoration: none;
}

.headline-text {
    font-weight: 600 !important;
}

blockquote,
q {
    quotes: none;
    border-left: 6px #3cb054 solid !important;
    background: #e0f5e5 !important;
    margin: 0px;
    margin-bottom: 1rem;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

mark {
    background-color: #eef0f0; /* gray[50] */
}

#search-bar input {
    padding: 0;
}

blockquote > p:last-child {
    margin-bottom: 0;
}
pre,
code {
    background-color: #eef0f0; /* gray[50] */
    color: #333;
    font-family: Roboto Mono, Monospaced;
    font-size: 0.75rem;
    border-radius: 3px;
    overflow: auto;
}
pre {
    padding: 10px;
}
code {
    padding: 4px;
}
pre > code {
    padding: 0px;
}

p.MuiTypography-h6.MuiTypography-paragraph,
p.MuiTypography-h5.MuiTypography-paragraph {
    clear: both;
}

.MuiToolbar-root {
    background: #3cb054 !important;
}

.footer {
    background: #e0f5e5 !important;
}

.MuiToolbar-root .MuiTypography-root {
    color: white !important;
    font-weight: bold;
}

.MuiIconButton-root {
    color: white !important;
}

.MuiInputBase-root {
    color: #ffffff !important;
}

.rotateMe {
    animation: 2500ms spin linear infinite;
}

table,
th,
td {
    border: 1px solid #eef0f0;
}

th {
    word-break: normal;
    background: #3cb054;
    font-size: 1.3rem;
    color: #fff;
    padding: 1rem;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

table tbody tr:nth-child(2n + 1) td {
    background: rgba(34, 34, 34, 0.05);
}

table tbody tr td {
    padding: 1rem;
    border-right: 1px solid rgba(34, 34, 34, 0.05);
    line-height: 1.9rem;
}

.katex,
.katex .vlist-t2 {
    margin-right: -2px;
    font-size: 22px;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Carousel Overrides */
.BrainhubCarousel {
    position: relative;
    color: white;
}
.BrainhubCarousel__customArrows {
    position: absolute;
    width: 0;
    top: calc(50%);
    transform: translateY(-50%);
    z-index: 100;
}
.BrainhubCarousel__custom-arrowLeft {
    left: 0;
}
.BrainhubCarousel__custom-arrowRight {
    right: 59px;
}

/* Start: Holiday Themes */

/* Halloween Theme & New Years Theme */
.halloween pre,
.halloween code,
.new-years pre,
.new-years code,
.mid-autumn-festival pre,
.mid-autumn-festival code {
    background-color: #13181b; /* darkBlack 300 */
    color: #e8eaea; /* black 50 */
}

.halloween.ph-item,
.new-years.ph-item,
.mid-autumn-festival.ph-item {
    background-color: #13181b; /* darkBlack 300 */
    border-color: #2b353a;
}

.halloween.ph-item::before,
.new-years.ph-item::before,
.mid-autumn-festival.ph-item::before {
    background: linear-gradient(to right, #13181b00 46%, #13181b55 50%, #13181b00 54%) 50% 50%;
}

.halloween.ph-item .ph-avatar,
.halloween.ph-item .ph-row > div > *,
.new-years.ph-item .ph-avatar,
.new-years.ph-item .ph-row > div > *,
.mid-autumn-festival.ph-item .ph-avatar,
.mid-autumn-festival.ph-item .ph-row > div > * {
    background-color: #2b353a;
}

#fireworks > canvas {
    height: 100% !important;
    width: 100% !important;
}
